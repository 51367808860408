import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Box, Grid, Input, Paper, Slider, Typography } from "@mui/material";
import { setState, startGetOpenHabItem, startSetOpenHabItemState } from "../Actions/onpenHab";

const OpenHabSlider = (props) => {


    useEffect(() => {
        props.itemName && getItemState(props.itemName);
    }, [props.itemName]);

    const getItemState = (itemName) => {
        props.dispatch(startGetOpenHabItem(itemName));
    }

    const onSliderChange = (event, newValue) => {
        console.log("onSliderChange", newValue);
        props.dispatch(setState(props.itemName, newValue));
    }

    const onSliderChangeCommitted = (event, newValue) => {
        props.dispatch(startSetOpenHabItemState(props.itemName, newValue));
    }

    const onInputBlur = (event) => {
        props.dispatch(startSetOpenHabItemState(props.itemName, event.target.value));
    }

    const onInputChange = (event) => {
        props.dispatch(startSetOpenHabItemState(props.itemName, event.target.value));
    }

    return (
        <Paper variant="outlined" sx={{ width: '100%', height: '110px' }}>
            <div style={{ padding: '16px', }}>
                <Typography align="left" gutterBottom>
                    {props.label ? props.label : props.itemName}
                </Typography>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item xs>
                        <Slider
                            size="large"
                            step={1}
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                            value={props.openHabItems[props.itemName] ? props.openHabItems[props.itemName].state : 0}
                            onChange={onSliderChange}
                            onChangeCommitted={onSliderChangeCommitted}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            value={props.openHabItems[props.itemName] ? props.openHabItems[props.itemName].state : 0}
                            size="small"
                            onChange={onInputChange}
                            onBlur={onInputBlur}
                            inputProps={{
                                step: 10,
                                min: 0,
                                max: 100,
                                type: 'number',
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );

}


const mapStateToProps = (state) => {
    return {
        openHabItems: state.openHab.items
    }
}
export default connect(mapStateToProps)(OpenHabSlider);
