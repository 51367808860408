import {createStore, combineReducers, applyMiddleware, compose } from 'redux';

import openHabReducer from '../reducers/openHabReducer';
import { thunk } from 'redux-thunk'

// import thunk from 'redux-thunk';
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    // Store createion 
    const store = createStore(
        combineReducers({
            openHab: openHabReducer,
        }),
        applyMiddleware(thunk)
    );

    return store;
};
