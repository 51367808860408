import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { startGetOpenHabItem } from "../Actions/onpenHab";
import { Button, Fab, Modal, Typography } from "@mui/material";
import CategoryIcon from "../config/CategoryIcon";
import Diagramm from "./Diagramm";
import CloseIcon from '@mui/icons-material/Close';

const Temperatur = (props) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.itemName && props.dispatch(startGetOpenHabItem(props.itemName));
        props.itemFeuchtigkeitName && props.dispatch(startGetOpenHabItem(props.itemFeuchtigkeitName));
    }, [props.itemName]);

    const bttnOpenModalClick = () => {
        setOpen(true);
    }

    return (
        <Fragment>
            <Button
                startIcon={<CategoryIcon category={props.openHabItems[props.itemName] && props.openHabItems[props.itemName].category} />}
                sx={{ width: '100%', height: '110px' }}
                variant="outlined"
                size="large"
                onClick={bttnOpenModalClick}
            >
                <div>
                    <Typography variant="h6" component="div">
                        {props.label}
                    </Typography>
                    <Typography variant='body2' component="div">
                        Temp.:  {props.openHabItems[props.itemName] && props.openHabItems[props.itemName].state}
                    </Typography>
                    {props.openHabItems[props.itemFeuchtigkeitName] &&
                        <Typography variant='body2' component="div">
                            Feuchte:  {props.openHabItems[props.itemFeuchtigkeitName].state}
                        </Typography>
                    }
                </div>
            </ Button>

            <Modal
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <div style={{ position: 'relative', backgroundColor: 'white', height: '530px', padding: '10px', borderRadius: '5px', width: '90%' }}>
                    <Fab
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                        size='small'
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </Fab>
                    {open && <Diagramm itemName={props.itemName} />}
                </div>
            </Modal>

        </Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        openHabItems: state.openHab.items
    }
}
export default connect(mapStateToProps)(Temperatur);

