import React, { useEffect } from "react";
import { connect } from 'react-redux';

import { startGetOpenHabItem, startSetOpenHabItemState } from "../Actions/onpenHab";
import { Button } from "@mui/material";
import CategoryIcon from "../config/CategoryIcon";

const OpenHabOnOff = (props) => {

    useEffect(() => {        
        props.itemName && readItemState(props.itemName);
    }, [props.itemName]);

    const onButtonClick = () => {
        const currentState = getItemState();

        if (props.itemName && currentState) {            
            const newState = currentState === "ON" ? "OFF" : "ON";
            props.dispatch(startSetOpenHabItemState(props.itemName, newState));
        }
    }

    const getItemState = () => {
        const state = props.openHabItems[props.itemName] && props.openHabItems[props.itemName].state;
        // wenn der state eine Zahl ist, müssen wir in ON oder OFF umwandeln
        if (!isNaN(state)) {
            return state > 0 ? "ON" : "OFF";
        }
        // sonst einfach den state zurückgeben
        return state;
    }

    const readItemState = (itemName) => {
        props.dispatch(startGetOpenHabItem(itemName));        
    }

    return (
        <div>
            <Button
                sx={{ width: '100%', height: '110px' }}
                startIcon={<CategoryIcon category={props.openHabItems[props.itemName] && props.openHabItems[props.itemName].category} />}
                onClick={onButtonClick}
                variant={getItemState() === "ON" ? "contained" : "outlined"}
                size="large">
                {props.label}
            </Button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        openHabItems: state.openHab.items
    }
}
export default connect(mapStateToProps)(OpenHabOnOff);