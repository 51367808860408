import React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import GoogleStundenplan from './Controls/GoogleStundenplan';
import OpenHabOnOff from './Controls/OpenHabOnOff';
import OpenHabSlider from './Controls/OpenHabSlider';
import Diagramm from './Controls/Diagramm';
import Temperatur from './Controls/Temperatur';
import { Grade } from '@mui/icons-material';
import Temperatur2 from './Controls/Temperatur2';


const calendarIdStundenplanElias = "d7c862ae861116487a906ade01517070cd39eef9d79a230d5a371b1f3f3df3ad@group.calendar.google.com";
const calendarIdStundenplanNoah = "4e2cee9c1d74f687ce900c171cfa1917404033eab4d6f0edf761eb351bb4c8f6@group.calendar.google.com";

const Dashboard = () => {


    return (
        <div style={{ height: '100%', width: '100%', margin: '16px' }}>
            <Grid container spacing={2}>

                <Grid item md={6}>
                    <Grid container spacing={2}>

                        <Grid item xs={4}>
                            <OpenHabOnOff label="Lampignons" itemName="TPLinkSteckdoseTerrasse__Betrieb" />
                        </Grid>
                        <Grid item xs={4}>
                            <OpenHabOnOff label="Lampe Opa" itemName="HueLampeOpa_Helligkeit" />
                        </Grid>
                        <Grid item xs={6}>
                            <OpenHabSlider label="Lampe Opa" itemName="HueLampeOpa_Helligkeit" />
                        </Grid>
                        <Grid item xs={4}>
                            <OpenHabOnOff label="Lampignons sync" itemName="TPLinkSteckdoseTerrasse__Betrieb" />
                        </Grid>
                        <Grid item xs={4}>
                            <Temperatur2 label="Wohnzimmer" itemName="HMThermometerMobil_Temperature" />
                        </Grid>
                        <Grid item xs={4}>
                            <Temperatur2 label="Außen" itemName="Wetterstation_Aussentemperatur" itemFeuchtigkeitName="Wetterstation_Luftfeuchtigkeit_aussen" />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item md={6}>

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <GoogleStundenplan calendarId={calendarIdStundenplanElias} titel={"Elias"} />
                        </Grid>
                        <Grid item xs={3}>
                            <GoogleStundenplan calendarId={calendarIdStundenplanNoah} titel={"Noah"} />
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
        </div>
    );
}

export default Dashboard;