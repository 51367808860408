import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { startGetOpenHabItem, startGetPersistenceData } from "../Actions/onpenHab";
import { Button, Fab, Modal, Tooltip, Typography } from "@mui/material";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import Diagramm from "./Diagramm";
import CloseIcon from '@mui/icons-material/Close';
import {AreaChart, ResponsiveContainer, Area } from "recharts";

const Temperatur = (props) => {

    const [open, setOpen] = useState(false);
    const [chartData, setChartData] = React.useState([]);

    useEffect(() => {
        props.itemName && props.dispatch(startGetOpenHabItem(props.itemName));
        props.itemFeuchtigkeitName && props.dispatch(startGetOpenHabItem(props.itemFeuchtigkeitName));
        props.itemName && props.dispatch(startGetPersistenceData(props.itemName));
    }, [props.itemName]);

    useEffect(() => {
        if (props.openHabData[props.itemName]) {
            setData(props.openHabData[props.itemName].data);
        }
    }, [props.openHabData[props.itemName]]);

    const setData = (stroeData) => {
        // die letzten 1500 werte des Arrays
        const nth = 50;
        const data = stroeData
            .slice(-1500)
            .filter((e, i) => i % nth === nth - 1);

        setChartData(data.map((item, index) => {
            return {
                value: parseFloat(item.state),
                time: new Date(item.time).toLocaleTimeString().slice(0, -3)
            }
        }))
    }

    return (
        <Fragment>
            <Button
                variant="outlined"
                className="kachel temperatur"
                style={{ position: 'relative' }}
                onClick={() => setOpen(true)}
            >
                <div style={{ width: '100%', height: '70px', position: 'absolute', top: 40, left: 0 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={chartData}>
                            <Area
                                type="monotone"
                                dataKey="value"
                                stroke="#ddd"
                                strokeWidth={2}
                                fill="#f6fafd"
                                dot={null}
                            />
                            <Tooltip />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                <div style={{ margin: '10px', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
                    <Typography variant="subtitle2" align="left" color="#1565c0">
                        {props.label}
                    </Typography>
                    <Typography variant="h4" color="#1565c0">
                        <ThermostatIcon />{props.openHabItems[props.itemName] && props.openHabItems[props.itemName].state}
                    </Typography>
                    {props.openHabItems[props.itemFeuchtigkeitName] &&
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        }}>
                            <WaterDropIcon fontSize="small" />
                            <Typography variant="body2" component='span' style={{ marginTop: '3px' }} >
                                {props.openHabItems[props.itemFeuchtigkeitName].state}
                            </Typography>
                        </div>
                    }
                </div>
            </Button>

            <Modal
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <div style={{ position: 'relative', backgroundColor: 'white', height: '530px', padding: '10px', borderRadius: '5px', width: '90%' }}>
                    <Fab
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                        size='small'
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </Fab>
                    {open && <Diagramm itemName={props.itemName} />}
                </div>
            </Modal>

        </Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        openHabItems: state.openHab.items,
        openHabData: state.openHab.data
    }
}
export default connect(mapStateToProps)(Temperatur);

