const openHabReducerDefaultState = {
    user: undefined,
    items: {},
    data: {}
};

const openHabReducer = (state = openHabReducerDefaultState, action) => {

    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
        case 'SET_ITEM':
            return {
                ...state,
                user: 'alex',
                items: {
                    ...state.items,
                    [action.itemName]: action.item
                }
            }
        case 'SET_ITEM_STATE':
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.itemName]: {
                        ...state.items[action.itemName],
                        state: action.state
                    }
                }
            }
        case 'SET_PERSISTENCE_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.itemName]: action.data
                }
            }
        default:
            return state;
    }
}

export default openHabReducer;