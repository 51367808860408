import React, { Fragment } from 'react';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';


const CategoryIcon = (props) => {


    const getIcon = (category) => {
        if(!category) return null;

        switch (category.toUpperCase()) {
            case "LIGHT":
                return <LightbulbIcon/>;
            case "TEMPERATURE":
                return <DeviceThermostatIcon/>;
            default:
                return null;
        }

    }

    return (
        <Fragment>
            {getIcon(props.category)}
        </Fragment>
    )
} 

export default CategoryIcon;