import axios from "axios";

const apiToken = "oh.familyDashboard.zDYxw0bxV7Zl0Wt4pcERTLhHVSEYTvhPg8pRdWqcZzz7Jkt5YZ6Yw9H7tk6ZnU27CReTjLD3wvxoIb3fSA";
const baseUrl = "https://192.168.1.115:8443";



export const setState = (itemName, state) => ({
    type: 'SET_ITEM_STATE',
    itemName,
    state,
});

export const setItem = (itemName, item) => ({
    type: 'SET_ITEM',
    itemName,
    item,
});

export const setPersistenceData = (itemName, data) => ({
    type: 'SET_PERSISTENCE_DATA',
    itemName,
    data,
});

const config = {
    headers: {
        Authorization: `Bearer ${apiToken}`,
        //accept: 'application/json',
        'Content-Type': 'text/plain'
    }
};


export const startGetOpenHabItem = (itemName) => {
    return (dispatch, getState) => {

        const url = baseUrl + "/rest/items/" + itemName; // + "/state";
        axios.get(url, config)
            .then(response => {
                dispatch(setItem(itemName, response.data));
            })
            .catch(error => {
                console.error("getOpenHabItemState", error);
            })
    };
}

export const startGetPersistenceData = (itemName) => {
    return (dispatch, getState) => {

        const url = baseUrl + "/rest/persistence/items/" + itemName;        
        axios.get(url, config)
            .then(response => {
                dispatch(setPersistenceData(itemName, response.data));
            })
            .catch(error => {
                console.error("getOpenHabItemState", error);
            })
    };
}


export const startSetOpenHabItemState = (itemName, state) => {
    return (dispatch, getState) => {

        const url = baseUrl + "/rest/items/" + itemName;
        axios.post(url, state, config)
            .then(response => {
                // Wenn erfolgreich, dann den neuen Status holen
                dispatch(startGetOpenHabItem(itemName));
            })
            .catch(error => {
                console.error("getOpenHabItemState", error);
            })
    };
}

