import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { startGetPersistenceData } from "../Actions/onpenHab";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';



const Diagramm = (props) => {

    const [xAxis, setXAxis] = React.useState([]);
    const [series, setSeries] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);

    useEffect(() => {
        props.itemName && props.dispatch(startGetPersistenceData(props.itemName));
    }, [props.itemName]);

    useEffect(() => {
        if (props.data[props.itemName]) {
            loadData();
        }
    }, [props.data[props.itemName]]);


    const loadData = () => {
        // die letzten 100 werte des Arrays
        const nth = 50;
        const data = props.data[props.itemName].data
            .slice(-1500)
            .filter((e, i) => i % nth === nth - 1);
        setXAxis(data.map((item, index) => new Date(item.time)));
        setSeries(data.map((item) => parseFloat(item.state)));

        setChartData(data.map((item, index) => {
            return {
                Temperatur: Math.round(parseFloat(item.state) * 100) / 100,
                time: new Date(item.time).toLocaleTimeString().slice(0, -3)
            }
        }))
    }

    return (
        <Fragment>
            {(xAxis.length > 0 && series.length > 0) &&
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={chartData}>
                        <CartesianGrid />
                        <Line
                            type="monotone"
                            dataKey="Temperatur"
                            stroke="#8884d8"
                            strokeWidth={4}
                            activeDot={{ r: 8 }}
                            dot={null}
                        />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            }
        </Fragment>
    )
};


const mapStateToProps = (state) => {
    return {
        data: state.openHab.data
    }
}
export default connect(mapStateToProps)(Diagramm);